/* Estilos base para dispositivos móviles */

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.content {
  text-align: center;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
}

h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

p {
  font-size: 1em;
  margin-bottom: 10px;
}

.progress-bar {
  width: 50%;
  height: 10px;
  background-color: rgb(157, 181, 207);
  margin: 20px auto;
}

.social-links {
  margin-top: 20px;
}

a {
  margin: 0 10px;
  text-decoration: none;
  color: #000;
}

@media (max-width: 768px) {
  .content {
    max-width: 300px;
  }
}