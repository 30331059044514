.ComponentStyle{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: .5fr 1fr .5fr;
    grid-template-areas: 
        "head head head"
        "articulesContent articulesContent articulesContent"
        "footer footer footer";
}

.containerLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .contentLoader {
    text-align: center;
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
  }