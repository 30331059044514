.articulesContent{
    display: grid;
    grid-area: articulesContent;
    margin-top: -220px;
    padding-top: 10px;
}
.articuleHighLigth{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
}
.articuleInfiniteImg{
    display: grid;
}
.articuleHighImg img{
    aspect-ratio: 9/4;
    width: 80%;
    object-fit: cover;
}
.articulesInfinites{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr .5fr;
    background-color: lavender;
}
.articuleInfiniteImg img{
    width: 30%;
    max-height: 300px;
    height: auto;
    object-fit: cover;
}
.articuleInfiniteText{
    display: grid;
}