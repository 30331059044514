.headLogoMenuBar{
    display: grid;
    grid-area: LogoMenuBar;
    column-gap: .5em;
    align-items: center;
}
.headMainMenuBtn{
    width: max-content;
    max-width: 100%;
    height: 4em;
    max-height: 1.5em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: black;
    border-radius: 5px 0 5px 0;
    text-align: center;
    border-right: 1px solid #083a55;
    border-bottom: 1px solid #083a55;
    box-shadow: 6px 4px 2px -3px #1f668dbd;
    -webkit-box-shadow: 6px 4px 2px -3px #083a55;
    -moz-box-shadow: 6px 4px 2px -3px #083a55;
}
.headMainMenuBtn:hover{
    color: rgba(79, 78, 95, 0.685);
    border-right: 1px solid #326d8d9d;
    border-bottom: 1px solid #326d8d9d;
    box-shadow: 6px 4px 2px -3px #326d8d9d;
    -webkit-box-shadow: 6px 4px 2px -3px #326d8d9d;
    -moz-box-shadow: 6px 4px 2px -3px #326d8d9d;
}
.imgLogoBar{
    width: max-content;
    height: max-content;
}
.textNameCompany{
    cursor: pointer;
}
.headMenuRigth{
    cursor: pointer;
}
.headClass{
    display: grid;
    grid-area: headerRigthCenter;
    align-items: center !important;
}
.imgs{
    width: 40%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}
.headerGridAll{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: .2fr 1fr;
    grid-area: head;
    grid-template-areas:
        "LogoMenuBar  LogoMenuBar"
        "headerRigthCenter headerRigthCenter";
    grid-row-gap: .5em;
    height: fit-content;
}
.slider{
    min-width: 800px;
    width: 100%;
}
.fieldArticulo{
    display: grid;
    grid-template-columns: 1fr 1fr;

}
.fieldArticulo img{
    aspect-ratio: 9/4;
    width: 100%;
    object-fit: cover;
    scroll-snap-align: end;
}
.images{
    display: grid;
    grid-template-rows: auto-fit;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}
fieldArticulo::-webkit-scrollbar{
    display: none;
}

.headLeftData{
    display: grid;
    grid-area: headLeft;
}
.resumenArticule{
    width: 100%;
    min-width: 800px;
    
}
.slider-list{
    text-align: center !important;
}